import { Box, GraphicContainer, PieCharts, Stack, StackRow } from "codekit";
import { useActionsGraphSelectFilters } from "../../../../utils/hooks/graphs/useActionsGraphSelectFilters";
import { useActionsGraphRangetFilters } from "../../../../utils/hooks/graphs/useActionsGraphRangetFilters";
import { useDataGraph } from "../../../../utils/hooks/graphs/useDataGraph";
import { useClasificationsFilter } from "../../../../utils/hooks/graphs/useClasificationsFilter";
import { useFilterGraph } from "../../../../utils/hooks/graphs/useFilterGraph";
import { useRedirectGraph } from "../../../../utils/hooks/useRedirectGraph";
import { useResponsiblesFilter } from "../../../../utils/hooks/graphs/useResponsiblesFilter";

import { useEffect } from "react";

export function Taskgraf({ graphName, MODIDXXX, PROIDXXX, graphKeyName, filtersFieldsGraph }) {
  const dataColorsCode = [
    {
      colorCellChart: "secondary-500",
      bgColorCards: "secondary-100",
      iconColor: "secondary-600",
      textColor: "secondary-600",
    },
    {
      colorCellChart: "error-500",
      bgColorCards: "error-100",
      iconColor: "error-400",
      textColor: "error-500",
    },
    {
      colorCellChart: "success-500",
      bgColorCards: "success-100",
      iconColor: "success-400",
      textColor: "success-600",
    },
  ];

  const [dataFilterOne] = useClasificationsFilter({ MODIDXXX, PROIDXXX });
  const [dataFilterTwo, metadataResponsibles, setClasification] = useResponsiblesFilter();
  const { handleSetFilter, handleResetFilterSelected, handleResetPesistFilter } = useFilterGraph();
  const { dataGraph, handleSetFilterJson, handleResetFilterJson } = useDataGraph({ MODIDXXX, PROIDXXX });

  const { handleRedirectWithRouter } = useRedirectGraph({ MODIDXXX, PROIDXXX });

  const [valueFilterOne, handleFilterOne, clsFilterOne] = useActionsGraphSelectFilters();
  const [valueFilterTwo, handleFilterTwo, clsFilterTwo] = useActionsGraphSelectFilters();
  const [valueFilterRange, handleFilterRange, clsFilterRange, convertRangeDate] = useActionsGraphRangetFilters();

  useEffect(() => {
    if (metadataResponsibles?.focused) {
      handleFilterTwo(dataFilterTwo[0]?.value);
      const filters = handleSetFilter("filterTwo", dataFilterTwo[0]?.value);
      handleSetFilterJson(filters);
    }
  }, [dataFilterTwo, handleFilterTwo, handleSetFilter, handleSetFilterJson, metadataResponsibles]);

  const handleClearFilters = (event) => {
    clsFilterOne();
    clsFilterTwo();
    clsFilterRange();
    handleResetFilterJson();
    handleResetFilterSelected();
    handleResetPesistFilter();
  };

  const handleVewMore = (event) => {
    handleResetPesistFilter();
    handleRedirectWithRouter();
  };

  const handleClickChart = (context) => {
    const filters = handleSetFilter("filterManagement", context.data.id);
    handleSetFilterJson(filters);
    handleRedirectWithRouter();
  };

  const handleFilterOneRefact = (context) => {
    setClasification(context.option.value);
    handleFilterOne(context.option.value);
    const filters = handleSetFilter("filterOne", context.option.value);
    handleSetFilterJson(filters);
    clsFilterTwo();
  };

  const handleFilterTwoRefact = (context) => {
    handleFilterTwo(context.option.value);
    const filters = handleSetFilter("filterTwo", context.option.value);
    handleSetFilterJson(filters);
  };

  const handleFilterRagRefact = (context) => {
    handleFilterRange(context);
    const filters = handleSetFilter("filterDate", convertRangeDate(context.option));
    handleSetFilterJson(filters);
  };

  return (
    <Box spacing={4}>
      <GraphicContainer
        containerName={graphName}
        graphicKeyName={graphKeyName}
        filterOneOptions={dataFilterOne}
        filterTwoOptions={dataFilterTwo}
        onChangeValueFilteroOne={handleFilterOneRefact}
        onChangeValueFilteroTwo={handleFilterTwoRefact}
        onChangeValueFilterDate={handleFilterRagRefact}
        valueFilterOne={valueFilterOne}
        valueFilterTwo={valueFilterTwo}
        valueFilterDate={valueFilterRange}
        clearFilters={handleClearFilters}
        onClickVerMas={handleVewMore}
        placeholderFilterOne='Seleccione la clasificación'
        placeholderFilterTwo='Seleccione el responsable'
      >
        <Stack gap={8}>
          <StackRow>
            <PieCharts
              onClickCellChart={handleClickChart}
              onClickIconCard={handleClickChart}
              data={dataGraph?.data}
              chartColors={dataColorsCode}
              labelOne={dataGraph?.meta?.kpi?.value}
              labelTwo={dataGraph?.meta?.kpi?.label}
            />
          </StackRow>
        </Stack>
      </GraphicContainer>
    </Box>
  );
}
