import { useState } from "react";

export function useActionsGraphRngFilGroup() {
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const today = new Date();
  const twoMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 2, today.getDate());
  const defaultRange = [formatDate(twoMonthsAgo), formatDate(today)];

  const [valueFilter, setValueFilter] = useState(defaultRange);

  const convertRangeDate = (rangeDateArray) => {
    return rangeDateArray.map((date) => {
      const [day, month, year] = date.split("/");
      return `${year}/${month}/${day}`;
    });
  };

  const handleSetValue = (context) => {
    setValueFilter(context.option);
  };

  const clsFilter = () => {
    setValueFilter(defaultRange);
  };

  return [valueFilter, handleSetValue, clsFilter, convertRangeDate];
}
