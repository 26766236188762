/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModuleSelected, setProcesSelected } from "../../libs/redux/slices/MenuSlice/menuSlice";
import { setRedirectGraph, unsetRedirectGraph, getWithRedirect } from "../../libs/redux/slices/Graficsx/graficsxSlice";
import { getMenuUser } from "../../libs/redux/slices/authSlice/authSlice";

export function useRedirectGraph({ MODIDXXX, PROIDXXX }) {
  const dispatch = useDispatch();
  const isWithRedirect = useSelector(getWithRedirect);
  const menuData = useSelector(getMenuUser);

  const handleNewWindow = () => {
    const url = process.env.REACT_APP_CIMA_WEB;
    window.open(`${url}`, "_blank");
  };

  const handleRedirectWithRouter = () => {
    dispatch(setRedirectGraph({ MODIDXXX, PROIDXXX }));
    handleNewWindow();

    setTimeout(() => {
      dispatch(unsetRedirectGraph());
    }, 20000);
  };

  const unsetRedirectPersist = () => {
    dispatch(unsetRedirectGraph());
  };

  const getModuleItemAndProcessItem = () => {
    let menuItems = {};
    menuData.forEach((menu) => {
      if (menu.MODIDXXX === MODIDXXX) {
        menu.CIMS0004.forEach((submenu) => {
          if (submenu.PROIDXXX === PROIDXXX) {
            menuItems = Object.assign({
              moduleItem: menu,
              processItem: submenu,
            });
          }
        });
      }
    });
    return menuItems;
  };

  const handleRouterComponents = () => {
    const { moduleItem, processItem } = getModuleItemAndProcessItem();
    dispatch(setModuleSelected(moduleItem));
    dispatch(setProcesSelected(processItem));
  };

  useEffect(() => {
    if (isWithRedirect) {
      handleRouterComponents();
      setTimeout(() => {
        dispatch(unsetRedirectGraph());
      }, 20000);
    }

  }, []);

  return {
    handleRedirectWithRouter,
    unsetRedirectPersist,
  };
}
