import { useState } from "react";
import { useDispatch } from "react-redux";
import Filter from "../../../utils/Filter";
import { setFilters } from "../../../libs/redux/slices/Graficsx/graficsxSlice";

// Este objeto permanece igual:
const graphFilter = {
  filterOne: {
    CAMPODES: "Estado",
    NOMCOLXX: "TARCYYYY.ESTAIDXX",
    TIPCAMXX: "SELECCION",
  },
  filterTwo: {
    CAMPODES: "Responsable",
    NOMCOLXX: "TARCYYYY.TARUSRRE",
    TIPCAMXX: "SELECCION",
  },
  filterDate: {
    CAMPODES: "Fecha de Vencimiento",
    NOMCOLXX: "TARCYYYY.REGFECXX",
    TIPCAMXX: "FECHA",
  },
};

export function useFilterGraph() {
  const dispatch = useDispatch();
  const [filtersSelected, setFiltersSelected] = useState([]);

  const getValueFilter = (filterName, VALUEXXX) => {
    const valuerFilter = { ...graphFilter[filterName] };
    valuerFilter.VALUEXXX = VALUEXXX;
    return valuerFilter;
  };

  const handleSetFiltersSelected = (filters) => {
    setFiltersSelected(filters);
  };

  const handleGetFiltersSelected = (filterName, VALUEXXX) => {
    const filterSelected = getValueFilter(filterName, VALUEXXX);

    if (filtersSelected.length === 0) {
      return [filterSelected];
    }

    const isSelected = filtersSelected.find(
      (filter) => filterSelected.NOMCOLXX === filter.NOMCOLXX
    );

    if (!isSelected) {
      return [...filtersSelected, filterSelected];
    }

    const newFilters = filtersSelected.map((filter) => {
      if (filterSelected.NOMCOLXX === filter.NOMCOLXX) {
        filter.VALUEXXX = VALUEXXX;
      }
      return filter;
    });

    return newFilters;
  };

  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };

  const today = new Date();
  const twoMonthsAgo = new Date(today);
  twoMonthsAgo.setMonth(today.getMonth() - 2);

  const getTransformedFilters = (filtersToTransform) => {
    const filter = new Filter(filtersToTransform);
    const filters = filter.getFilters();
    const defaultFilter = {
      "TARCYYYY.REGFECXX": {
        BETWEEN: [formatDate(twoMonthsAgo), formatDate(today)],
      },
    };
    const hasRegFecxx = filters.some(
      (item) => Object.keys(item).includes("TARCYYYY.REGFECXX")
    );
    return hasRegFecxx ? filters : [...filters, defaultFilter];
  };


  const handleSetFilter = (filterName, VALUEXXX, states = []) => {
    const newFilter = getValueFilter(filterName, VALUEXXX);
    let filters = [newFilter];
    if (states.length > 0) {
      const stateFilter = {
        CAMPODES: "Estado",
        NOMCOLXX: "TARCYYYY.ESTAIDXX",
        TIPCAMXX: "SELECCION",
        TIPOPERX: "IN",
        VALUEXXX: states[0] + "," + states[1],
      };

      filters.push(stateFilter);
    }
    const filtersTransformed = getTransformedFilters(filters);
    dispatch(setFilters(filtersTransformed));
    handleSetFiltersSelected(filters);
    return handleConsultFilter(filtersTransformed);
  };

  const handleResetPesistFilter = () => {
    const filters = [
      {
        "TARCYYYY.REGFECXX": {
          BETWEEN: [formatDate(twoMonthsAgo), formatDate(today)],
        },
      },
    ];
    dispatch(setFilters(filters));
  };

  const handleResetFilterSelected = () => {
    setFiltersSelected([]);
  };

  const handleConsultFilter = (filters) => {
    return {
      filtersJson: JSON.stringify(filters),
    };
  };

  return {
    handleSetFilter,
    handleResetPesistFilter,
    handleResetFilterSelected,
  };
}
